import { Router } from "preact-router";
import Exhibit from "../routes/exhibit";
import Home from "../routes/home";

function App() {
	return (
		<div id="app">
			<Router>
				<Home path="/" />
				<Exhibit path="/x/:id" />
			</Router>
		</div>
	);
}

export default App;
